<template>
  <div class="forget_box" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="forget_backimg"></div>
    <div class="forget_content">
      <p class="forget_logo">
        <img src="../assets/icon/cc-logo-white.svg" alt />
      </p>
      <!--  -->
      <div class="forget_infor">
        <div class="forget_fill">
          <el-form ref="data" :model="data" :rules="rules">
            <p class="forget_title">Forget Your Password</p>
            <el-form-item prop="password">
              <p class="tip tip_item">Password</p>
              <el-input
                v-model="data.password"
                type="password"
                autocomplete="new-password"
                placeholder="Please enter your password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item prop="confirm_password">
              <p class="tip tip_item">Confirm Password</p>
              <el-input
                v-model="data.confirm_password"
                type="password"
                autocomplete="new-password"
                placeholder="Please enter your password"
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            @click="forgetpassword('data')"
            class="verifi"
            >SIGN UP NOW</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiForgetpassword } from "../API/api";
import { Base64 } from "js-base64";
function serilizeUrl(url) {
  var urlObject = {};
  if (url) {
    var urlArray = url.split("&");
    for (var i = 0, len = urlArray.length; i < len; i++) {
      var urlItem = urlArray[i];
      var item = urlItem.split("=");
      urlObject[item[0]] = item[1];
    }
    return urlObject;
  }
  return null;
}
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,32}$/;
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (this.data.confirm_password !== "") {
          this.$refs.data.validateField("confirm_password");
        } else {
          if (!reg.test(value)) {
            callback(
              new Error(
                "The password must be 7-32 characters and contain at lease 1 letter and 1 number."
              )
            );
          } else {
            callback();
          }
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,32}$/;
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== this.data.password) {
        callback(new Error("Two inputs don't match!"));
      } else {
        if (!reg.test(value)) {
          callback(
            new Error(
              "The password must be 7-32 characters and contain at lease 1 letter and 1 number."
            )
          );
        } else {
          callback();
        }
      }
    };
    return {
      data: {
        email: "",
        nonce: "",
        password: "",
        confirm_password: "",
        user_type: "pm",
      },
      rules: {
        password: [{ validator: validatePass, trigger: ["change", "blur"] }],
        confirm_password: [
          { validator: validatePass2, trigger: ["cahnge", "blur"] },
        ],
        // email: [
        //   {
        //     required: true,
        //     message: "this information is required",
        //     trigger: ["blur","change"],
        //   },
        // ],
      },
      showforget: true,
      fullscreenLoading: false,
    };
  },
  methods: {
    forgetpassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          delete this.data.confirm_password;
          apiForgetpassword(this.data)
            .then((res) => {
              this.fullscreenLoading = false;
              this.$router.push("/login");
            })
            .catch((err) => {
              this.fullscreenLoading = false;
              this.$message.error(
                err.response.data && err.response.data.detail
                  ? err.response.data.detail
                  : "Password modification failed"
              );
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    let url = serilizeUrl(Base64.decode(this.$route.params.id));
    this.data.nonce = url.nonce;
    this.data.email = url.email;
  },
};
</script>
<style lang="scss" scoped>
.forget_box {
  width: 100%;
  height: 100vh;
  position: relative;
  .forget_backimg {
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/20191218172529.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    -webkit-filter: blur(5px); /* Chrome, Opera */
    -moz-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(3px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); /* IE6~IE9 */
  }
  .forget_content {
    width: 500px;
    height: 450px;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 225px);
    .forget_logo {
      text-align: left;
    }
    .forget_infor {
      padding: 40px;
      background-color: #ffffff;
      box-shadow: 0px 5px 20px #33333340;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .forget_fill {
        width: 500px;
        .forget_btn {
          margin-top: 20px;
        }
      }
    }
    .tip {
      text-align: left;
      margin-bottom: 10px;
      color: #333333;
      font-size: 16px;
    }
    .tip_item {
      margin: 0;
    }
    .forget_title {
      font-size: 34px;
      text-align: left;
      margin: 0;
      color: #678993;
      font-family: PingFangSC-Medium, sans-serif;
    }
    /deep/ .el-input__inner {
      height: 50px;
      line-height: normal;
      background: #fff;
    }
    /deep/ .el-form-item {
      margin-bottom: 10px;
    }
  }
  .el-button {
    background-color: #678993;
    width: 100%;
    color: #ffffff;
    margin-top: 20px;
    font-size: 24px;
  }
  @media (max-width: 992px) {
    .forget_content {
      left: 0;
      top: 30px;
      width: 100%;
      height: 100%;
      .forget_infor {
        display: block;
        height: 100%;
        width: 100%;
        padding: 20px 20px 0 20px;
        .forget_fill {
          width: calc(100% - 40px);
        }
      }
      /deep/ .el-input {
        width: 100%;
      }
      .forget_logo {
        text-align: center;
        img {
          width: 60%;
        }
      }
    }
    .forget_backimg {
      background-size: contain;
      opacity: 0.8;
    }
  }
}
</style>
